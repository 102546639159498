<template>
  <div v-if="execs" class="team">
    <TextDisplay class="team_subtitle" :text="subtitle" font-style="title"/>
    <TextDisplay class="team_position" text="Executive Team" font-style="medium"/>
    <div class="team_list">
      <Profile class="team_list_item" :item="mem" :key="mem.name" v-for="mem in sortedExecs"/>
    </div>
  </div>
  <div v-else class="team">
    <TextDisplay class="team_position" text="Core Team" font-style="medium"/>
    <div class="team_list">
      <Profile class="team_list_item" :item="mem" :key="mem.name" v-for="mem in sortedMembers"/>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/shared/Profile";
import TextDisplay from "@/components/shared/Text";

export default {
  name: "Team",
  components: {TextDisplay, Profile},
  props: {
    execs: {
      type: Boolean,
      description: "If we show executives or not",
    },
  },
  data() {
    return {
      subtitle: "Who our team is",
      members: [
        {
          name: "Ghiagal",
          image: require('../assets/profile_pics/blank.png'),
          description: "Hello all, Ghiagal here. My main role is graphic designer. As the designer I create graphics as needed for marketing and anything else the executives may need.\n" +
              "I consider myself a creative thinker who sees elements of design in everything around me. My imagination forever seems to be taking the images I see in life and spinning them into my work. Sometimes I wish it would slow down just a little, but I use my mind’s restless energy to gain new knowledge and share with others what I’ve learned. \n" +
              "L0N3STARR, my son, brought me into crypto and I have loved combining my infatuation with gambling, technology, and research as I've participated in different projects, both as an investor and as a team member.",
          links: {},
          position: "Core Team"
        },
        {
          name: "BlockChainz",
          image: require('../assets/profile_pics/blockchainz.jpg'),
          description: "I have a huge passion for fostering success in this space. I am a firm believer that the NFT space has amazing untapped potential for artists to reach a wider platform, or for people to make real change in the world. I am here to help your vision become reality. I have experience in community management, event management, and assisting owners with planning and execution. My best quality is my determination and drive, and I will work for your project as if it were my own!",
          links: {},
          position: "Executive"
        },
        {
          name: "L0N3STARR",
          image: require('../assets/profile_pics/lonestarr.png'),
          description: "I serve as a “jack of all trades” having my hands in many cookie jars, including community management, running events, negotiating contracts, configuring Discord servers and bots, writing lore, writing code, and whatever else I feel like on a given day.  In the real world I’m an electrical engineer with an incredibly supportive wife and the two coolest little boys alive.  I also have an  awesome, dopey dog and a sweet angel pup who we miss very much!",
          links: {
            instagram: "https://instagram.com/l0n3starr_gaming",
            twitter: "https://twitter.com/l0n3starr",
            twitch: "https://twitch.tv/l0n3starr",
            opensea: "https://opensea.io/l0n3starr",
          },
          position: "Executive"
        },
        {
          name: "GoldenEye",
          image: require('../assets/profile_pics/goldeneye.png'),
          description: " I truly enjoy project management and watching everyone around me become successful.  I take my RL experiences in business management and marketing and bring it to this wonderful space!  I manage 18 corporate team members and 2,000 field employees at my everyday career! Dealing with Private Equity, Venture Capitalists, Lawyers, Lenders etc are things I am comfortable with to help any of our clients in their communications. Many people always say we are still early in this space, but we truly still are! The ceiling is limitless and with our expertise in this space and IRL, we are here to fit in anywhere we can!",
          links: {},
          position: "Executive"
        },
        {
          name: "manicDefi",
          image: require('../assets/profile_pics/manic.jpg'),
          description: "I am a full time student by day. I specialize in operational management, community management and marketing… but truthfully I dabble in all aspects of Integris. I have a burning love for the blockchain and intend to make it my future. I currently am pursuing a Bachelors in IT with a concentration in software development, I am happily married, and a proud mama of two beautiful girls.",
          mantra: "Hanuʻu ke kai i Mokuola ",
          links: {},
          position: "Executive"
        },
        {
          name: "G Huff",
          image: require('../assets/profile_pics/blank.png'),
          description: "",
          links: {},
          position: "Core Team"
        },
        {
          name: "Gerthworm James",
          image: require('../assets/profile_pics/gerthworm.jpg'),
          description: "Hello my name is James a - clever play with my screen name. I have worked retail and medicine as an x-ray tech and now I audit medical charts for an urgent care company. I have far too many hobbies to list. I got into crypto last year but only realized how deep the hole actually is a couple of months ago. When I have free time I like to play games and learn new things.",
          links: {
            twitter: "https://twitter.com/gerthwormjames",
          },
          position: "Core Team"
        },
        // {
        //   name: "Ginger King",
        //   image: require('../assets/profile_pics/ginger.png'),
        //   description: "Hey I'm GK! I'm the team nerd. I play D&D, video games, and I'm the team Web Developer. I am a professional engineer and love learning how things work. In the blockchain world I specialize in moderation, algorithms and keeping a smile on most peoples' faces. My screen name often is related to gingers or red-heads.",
        //   links: {
        //     twitter: "https://twitter.com/HailGingerKing",
        //   },
        //   mantra: "With great ability comes great accountability",
        //   position: "Core Team"
        // },
        {
          name: "J",
          image: require('../assets/profile_pics/blank.png'),
          description: "",
          links: {},
          position: "Core Team"
        },
        {
          name: "Mac",
          image: require('../assets/profile_pics/mac.png'),
          description: "Hey, I'm Mac! I'm new to Integris but proud to call them clan. I specialize in tech/security support, event and games management, moderation, bot configuration, sourcing talent, social media, and marketing. I'm friendly and patient and love animals and the outdoors. I consider myself a logical thinker and problem solver and find peace in working through issues. I'm an electrician/network technician in real life with a certification in mechatronics. Currently working with SCADA, communications, CCTV and other equipment.",
          links: {
            twitter: "https://twitter.com/WillTriMac?t=4SsJqmYU1mBBbVEEumkv2w&s=09",
          },
          position: "Core Team"
        },
        // {
        //   name: "Meriam",
        //   image: require('../assets/profile_pics/meriam.jpg'),
        //   description: "As a passionate IT headhunter and an NLP coach, I love interacting with people and building trust. Joined the web3 space for a while now, I enjoy exploring and learning more everyday.",
        //   links: {}
        // },
        {
          name: "PONO",
          image: require('../assets/profile_pics/pono.png'),
          description: "Long time member of the Integris team. Most of my work is oriented to community relations, marketing, and collaborations. In real life I am a husband, father of 3, nurse manager for a hospice and home health nursing team, nurse informatics grad, hunter, fitness nut, hustler, and friend. If I could choose the most valuable attribute I feel I have, it would be grit.",
          links: {
            twitter: "https://twitter.com/ClayBones8",
            opensea: "https://opensea.io/PONO8",
          },
          position: "Core Team"
        },
        {
          name: "seemebe",
          image: require('../assets/profile_pics/seemebe.jpg'),
          description: "Hello my name is Sarah, and I am a fundamental part of our Integris Social Media team. When I’m not in Twitter Spaces or looking for new projects, I can be found roller skating, swimming or lizard lounging.  I am a dog aficionado, crypto connoisseur, and a burrito enthusiast.  I’m an early investor in Gala Games and have been on the moderation team of several successful coins and NFT projects. In real life I am a billing and enrollment specialist for a health insurance provider, as well as a freelance customer service consultant.",
          links: {},
          position: "Core Team"
        },
        // {
        //   name: "SematicArk",
        //   image: require('../assets/profile_pics/sematic.jpg'),
        //   description: "Integrated into the web 3 space in 2020. Heavy concentration on NFT project community management and operations. Specialize in branding and overall growth and success to our clients. At Integris my goal is to help define weaknesses, needs, and to provide solutions to our clients in overall success and progression of client needs.",
        //   links: {}
        // },
        {
          name: "ZephyrX",
          image: require('../assets/profile_pics/zephyrx.jpeg'),
          description: "I am a team member of Integris. I'm primarily responsible for being a moderator in the servers and managing bot events besides that one of my strong points is research and analysis. In my day to day life I'm an electrical and electronics engineer and self-employed in the field of logistics! Also I've keen interest to be up-to-date with technical publications and articles!",
          links: {
            twitter: "https://twitter.com/shgr11014",
            opensea: "https://opensea.io/ZephyrX",
          },
          position: "Core Team"
        },
      ],
    };
  },
  methods: {},
  computed: {
    sortedExecs() {
      let execs = this.members.filter(a => {
        return a.position === "Executive";
      });
      execs.sort((a, b) => a.name.localeCompare(b.name));
      return execs;
    },
    sortedMembers() {
      let m = this.members.filter(a => {
        return a.description !== "" && a.position !== "Executive";
      });
      m.sort((a, b) => a.name.localeCompare(b.name));
      return m;
    },
  },
}
</script>

<style scoped lang="scss">
.team {
  &_title {

  }

  &_subtitle {

  }

  &_position {
    margin-bottom: 12px;
  }

  &_list {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &_item {
      margin-bottom: 5%;
      max-width: 275px;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      @media screen and (max-width: $screen-medium) {
        margin-bottom: 0;
        border-bottom: 1px solid $color-blue-light;
      }
      @media screen and (max-width: $screen-small) {
        margin-bottom: 0;
        padding-bottom: 10px;
      }
    }
  }
}


</style>